import React from 'react'
import Page from '../components/Page'

const TranslationsPage = () => (
  <Page title="Monitoring">
    <h1 className="text-2xl text-center w-full mt-8">TRANSLATIONS IN LITIGATION AND IPR PROCEEDINGS</h1>
      <p className="mt-8 w-2/3 text-center">
        Translations are ALWAYS a DECIDING FACTOR for litigation and IPR proceedings.<br/>
        <br/>
        Translations, provided and certified by our expert technical linguists, are definitive evidentiary documents, routinely recognized by the USPTO and US federal district courts<br/>  
      </p>
  </Page>
)

export default TranslationsPage